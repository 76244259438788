/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang = "en", meta }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            baseURL
            title
            description
            author
          }
        }
      }
    `
  )

  let title = meta ? meta.title : site.siteMetadata.title
  if (!title) {
    title = site.siteMetadata.title
  }
  let url = site.siteMetadata.baseURL
  if (meta.url) {
    url += `/${meta.url}`
  }
  const titleTemplate = (title || "").includes("|")
    ? `%s`
    : `%s | ${site.siteMetadata.title}`
  const metaDescription = meta
    ? meta.description
    : description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          rel: `canonical`,
          content: url,
        },
        {
          property: `og:title`,
          content: title.split(" | ")[0],
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.title,
        },
        {
          property: `og:site_url`,
          content: site.siteMetadata.baseURL,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title.split(" | ")[0],
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: {},
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.object,
  title: PropTypes.string,
}

export default SEO
