import React, { useState } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import "../assets/css/nav.scss"
import { Sort } from "../services/utils"
import Logo from "../../static/logo.svg"

const RenderLinks = links => {
  return links.map((link, i) => {
    let linkName = link.navTitle ? link.navTitle : link.pageTitle
    if (!linkName) {
      linkName = link.name
    }
    return (
      <li key={`/${link.url || ""}`}>
        <Link to={`/${link.url || ""}`}>{linkName}</Link>
      </li>
    )
  })
}

const Nav = () => {
  let [menuOpen, setMenuOpen] = useState(false)
  return (
    <header>
      <nav>
        <StaticQuery
          query={graphql`
            query {
              tst {
                allNavigations(where: { name: "Header" }) {
                  image {
                    image {
                      publicUrl
                    }
                  }
                  pages {
                    name
                    pageTitle
                    navTitle
                    url
                  }
                  pageSort
                }
              }
            }
          `}
          render={data => {
            let nav = data.tst.allNavigations[0]
            let pages = Sort(nav.pages, nav.pageSort)
            return (
              <div>
                <Link to={`/`}>
                  <img src={Logo} alt="The Stil Trust" />
                </Link>
                <menu className={`${menuOpen ? "open" : ""}`}>
                  <ul>
                    {RenderLinks(pages)}
                    <li className="mobile-only">
                      <a href="https://app.thestiltrust.com/mens/login">
                        Login
                      </a>
                    </li>
                    <li className="mobile-only">
                      <a href="https://app.thestiltrust.com/mens/join">Join</a>
                    </li>
                  </ul>
                  <aside>
                    <a href="https://app.thestiltrust.com/mens/login">Login</a>
                    <span className="divider">|</span>
                    <a href="https://app.thestiltrust.com/mens/join">Join</a>
                  </aside>
                </menu>
                <div
                  class={`hamburger ${menuOpen ? "active" : ""}`}
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  <em></em>
                  <em></em>
                  <em></em>
                </div>
              </div>
            )
          }}
        />
      </nav>
    </header>
  )
}

export default Nav
