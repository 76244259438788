import React from "react"
import PropTypes from "prop-types"
import { Link, StaticQuery, graphql } from "gatsby"
import Logo from "../../static/logo-white.svg"
import "../assets/css/footer.scss"

function RenderLinks(links) {
  return links.map((link, i) => {
    return (
      <div className="link" key={`/${link.url}`}>
        <div>
          <Link to={`/${link.url}`}>{link.name}</Link>
        </div>
      </div>
    )
  })
}

const Footer = ({ data }) => {
  return (
    <footer>
      <StaticQuery
        query={graphql`
          query {
            tst {
              allNavigations(where: { name: "Footer" }) {
                image {
                  image {
                    publicUrl
                  }
                }
                pages {
                  name
                  url
                }
              }
            }
          }
        `}
        render={data => {
          let nav = data.tst.allNavigations[0]
          return (
            <div>
              <img src={Logo} alt="The Stil Trust" />
              <div className="links">{RenderLinks(nav.pages)}</div>
              <div className="social talign-center">
                <Facebook />
                <Instagram />
                <Pinterest />
              </div>
            </div>
          )
        }}
      />
    </footer>
  )
}

const Facebook = () => {
  return (
    <a
      href="https://www.facebook.com/thestiltrust"
      target="_blank"
      rel="noopener nofollow"
    >
      <svg viewBox="0 0 100 192.59" height="20">
        <path
          d="M449.78,305.4V336H431.61q-9.94,0-13.42,4.17t-3.48,12.5v21.87h33.92l-4.52,34.26h-29.4V496.6H379.3V408.75H349.78V374.49H379.3V349.26q0-21.52,12-33.39T423.39,304Q440.42,304,449.78,305.4Z"
          transform="translate(-349.78 -304.01)"
          style={{ fill: "#d0bfbc" }}
        />
      </svg>
    </a>
  )
}

const Instagram = () => {
  return (
    <a
      href="https://www.instagram.com/thestiltrust/"
      target="_blank"
      rel="noopener nofollow"
    >
      <svg height="20" viewBox="0 0 100 100">
        <path
          d="M416.45,400.3a16.66,16.66,0,1,0-4.88,11.79A16.08,16.08,0,0,0,416.45,400.3Zm9,0A25.56,25.56,0,1,1,418,382.14,24.74,24.74,0,0,1,425.43,400.3Zm7-26.69a6,6,0,1,1-1.75-4.23A5.76,5.76,0,0,1,432.46,373.61Zm-32.68-14.32-5,0q-4.51,0-6.87,0t-6.28.19a52.8,52.8,0,0,0-6.7.65,26.2,26.2,0,0,0-4.66,1.21,17,17,0,0,0-9.5,9.5,26.2,26.2,0,0,0-1.21,4.66,52.8,52.8,0,0,0-.65,6.7c-.11,2.63-.17,4.72-.2,6.29s0,3.85,0,6.86,0,4.68,0,5,0,2,0,5,0,5.31,0,6.87.09,3.66.2,6.29a52.8,52.8,0,0,0,.65,6.7,26.2,26.2,0,0,0,1.21,4.66,17,17,0,0,0,9.5,9.5,26.2,26.2,0,0,0,4.66,1.21,52.8,52.8,0,0,0,6.7.65q3.94.16,6.28.19t6.87,0l5,0,5,0q4.53,0,6.87,0c1.56,0,3.66-.08,6.28-.19a52.79,52.79,0,0,0,6.71-.65,26.07,26.07,0,0,0,4.65-1.21,17,17,0,0,0,9.51-9.5,25.43,25.43,0,0,0,1.2-4.66,50.84,50.84,0,0,0,.66-6.7c.1-2.63.17-4.72.19-6.29s0-3.85,0-6.87,0-4.67,0-5,0-2,0-5,0-5.3,0-6.86-.09-3.66-.19-6.29a50.84,50.84,0,0,0-.66-6.7,25.43,25.43,0,0,0-1.2-4.66,17,17,0,0,0-9.51-9.5,26.07,26.07,0,0,0-4.65-1.21,52.79,52.79,0,0,0-6.71-.65c-2.62-.11-4.72-.17-6.28-.19s-3.85,0-6.87,0Zm50,41q0,14.91-.32,20.64-.66,13.55-8.08,21t-21,8.07q-5.73.33-20.64.32T379.14,450q-13.53-.66-21-8.07t-8.07-21q-.33-5.73-.33-20.64t.33-20.63q.64-13.55,8.07-21t21-8.07q5.73-.33,20.64-.33t20.64.33q13.54.64,21,8.07t8.08,21Q449.78,385.4,449.78,400.3Z"
          transform="translate(-349.78 -350.3)"
          style={{ fill: "#d0bfbc" }}
        />
      </svg>
    </a>
  )
}

const Pinterest = () => {
  return (
    <a
      href="https://www.pinterest.com/thestiltrust/"
      target="_blank"
      rel="noopener nofollow"
    >
      <svg height="20" viewBox="0 0 100 130">
        <path
          d="M349.78,381.94a43.13,43.13,0,0,1,2.93-15.89,40.83,40.83,0,0,1,8.09-13,55.09,55.09,0,0,1,26.33-15.7,61.54,61.54,0,0,1,15.78-2,51.66,51.66,0,0,1,23,5.2,43.34,43.34,0,0,1,17.26,15.12A39.36,39.36,0,0,1,449.78,378a72.48,72.48,0,0,1-1.48,14.69,59,59,0,0,1-4.69,13.82,48.06,48.06,0,0,1-7.81,11.68,33.79,33.79,0,0,1-11.33,8,35.66,35.66,0,0,1-14.77,3,24.2,24.2,0,0,1-10.54-2.5,15.67,15.67,0,0,1-7.5-6.88q-.78,3-2.19,8.79t-1.84,7.42c-.28,1.12-.82,3-1.6,5.55a37,37,0,0,1-2,5.55q-.86,1.68-2.5,4.88a55.56,55.56,0,0,1-3.59,6.06q-1.95,2.85-4.85,6.75l-1.09.39-.7-.78q-1.17-12.25-1.17-14.68a89.09,89.09,0,0,1,1.67-16.14q1.68-8.94,5.2-22.46T381,395.38q-2.49-5.07-2.5-13.2A20.63,20.63,0,0,1,382.6,370q4-5.7,10.31-5.7a9.16,9.16,0,0,1,7.42,3.16,12,12,0,0,1,2.66,8q0,5.16-3.44,14.92T396.11,405a10.64,10.64,0,0,0,3.52,8.17,12.1,12.1,0,0,0,8.51,3.24,16.62,16.62,0,0,0,8-2,17.9,17.9,0,0,0,6.13-5.31,45.17,45.17,0,0,0,4.38-7.42,43.71,43.71,0,0,0,3-8.63,86.87,86.87,0,0,0,1.56-8.67,63.26,63.26,0,0,0,.51-7.78q0-13.51-8.56-21.05T400.8,348q-15.63,0-26.1,10.12t-10.46,25.66a22.65,22.65,0,0,0,1,6.64,23.07,23.07,0,0,0,2.11,5.08c.76,1.25,1.46,2.43,2.11,3.55a6,6,0,0,1,1,2.39,19.06,19.06,0,0,1-1.17,5.7q-1.19,3.51-2.89,3.51a11.94,11.94,0,0,1-1.33-.23,16,16,0,0,1-7.07-4.37,22.79,22.79,0,0,1-4.77-7.39,47.85,47.85,0,0,1-2.54-8.43A42.31,42.31,0,0,1,349.78,381.94Z"
          transform="translate(-349.78 -335.3)"
          style={{ fill: "#d0bfbc" }}
        />
      </svg>
    </a>
  )
}

Footer.defaultProps = {
  data: {},
}

Footer.propTypes = {
  data: PropTypes.object,
}

export default Footer
